import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTableModule} from '@angular/material/table';
import {
  MatPaginatorModule,
  MatInputModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatDividerModule,
} from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DetailsComponent } from './components/details/details.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpErrorInterceptor } from './interceptor/httpconfig.interceptor';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PaidClaimsComponent } from './components/paid-claims/paid-claims.component';
import { ApprovedClaimsComponent } from './components/approved-claims/approved-claims.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ReviewedClaimsComponent } from './components/reviewed-claims/reviewed-claims.component';
import { RejectedClaimsComponent } from './components/rejected-claims/rejected-claims.component';
import { ReportsComponent } from './components/reports/reports.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PendingClaimsComponent } from './components/pending-claims/pending-claims.component';
// import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DetailsComponent,
    NavbarComponent,
    FooterComponent,
    HeroSectionComponent,
    AdminComponent,
    DashboardComponent,
    PaidClaimsComponent,
    ApprovedClaimsComponent,
    PaymentsComponent,
    ReviewedClaimsComponent,
    RejectedClaimsComponent,
    ReportsComponent,
    PendingClaimsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTableExporterModule,
    MatProgressSpinnerModule,
    PdfViewerModule,
    MatSnackBarModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableExporterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
