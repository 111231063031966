import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit,ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observer } from 'rxjs';
import { IsAuthorisedService } from 'src/app/services/is-authorised.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private isauth:IsAuthorisedService){

  }
  currentUser=this.isauth.userName;
  // @ViewChild(MatSidenav)
  // sidenav!: MatSidenav;
  // constructor(private Observer:BreakpointObserver) { }

  ngOnInit() {
  }

  // ngAfterViewInit() {
  //   this.Observer.observe(['(max-width: 800px)']).subscribe((res) => {
  //     if (res.matches) {
  //       this.sidenav.mode = 'over';
  //       this.sidenav.close();
  //     } else {
  //       this.sidenav.mode = 'side';
  //       this.sidenav.open();
  //     }
  //   });
  // }
}
