import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NotificationService } from "src/app/services/notification.service";
import { IsAuthorisedService } from "src/app/services/is-authorised.service";
@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
  ID: string;
  claim: any;
  claimForm: FormGroup;
  isLoading = true;

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public notifier: NotificationService,
    private user: IsAuthorisedService
  ) {}

  username = this.user.userName;

  get f() {
    return this.claimForm.controls;
  }

  ngOnInit() {
    this.ID = this.activatedRoute.snapshot.params.ID;
    this.getClaim();
    this.claimForm = this.formBuilder.group({
      cicApprove: [""],
      bluewave: [""],
      cicReject: [""],
      claimDocument: [""],
    });
  }

  getClaim() {
    this.api.get("/Claims/" + this.ID).subscribe((res) => {
      this.claim = res;
      console.log(this.claim.file);
      console.log(this.claim);
      this.isLoading = false;
    });
  }

  reviewClaim() {
    // this.reviewData.append('id', this.ID);
    // this.reviewData.append('comment', this.claimForm.get('bluewave').value);
  }

  approveClaim() {
    // if (this.claimForm.get('cicApprove').value) {
    //   this.notifier.showError('The comment is required');
    // }
    const comment = JSON.parse(
      JSON.stringify({ comment: this.claimForm.get("cicApprove").value })
    );

    this.api
      .post("/Claims/" + this.ID + "/approve", comment)
      .subscribe((res) => {
        this.notifier.showSuccess("The claim has been approved");
        this.getClaim();
      });
  }

  // uploadFIle() {
  //   // if (this.claimForm.get('cicApprove').value) {
  //   //   this.notifier.showError('The comment is required');
  //   // }
  //   const file = JSON.parse(
  //     JSON.stringify({ file: this.claimForm.get('claimDocument').value })
  //   );

  //   this.api.put('/Claims/' + this.ID + '/file', file).subscribe(
  //     res => {
  //       this.notifier.showSuccess('The claim file has been updated successfully');
  //       this.getClaim();
  //     }
  //   );
  // }
  claimFileSelected(event) {
    if (event.target.files.length > 0) {
      this.claimForm.get("claimDocument").setValue(event.target.files[0]);
    }
    console.log(this.claimFileSelected);
  }

  uploadFile = async () => {
    const fileDoc = await this.toBase64(
      this.claimForm.get("claimDocument").value
    );
    const data = await JSON.parse(JSON.stringify({ file: fileDoc }));
    this.api.put(`/Claims/${this.ID}/file`, data).subscribe((res) => {
      this.notifier.showSuccess("The claim file has been updated successfully");
    });
    this.claimForm.reset();
  };

  rejectClaim() {
    // if (this.claimForm.get('cicReject').value) {
    //   this.notifier.showError('The comment is required');
    // }

    const comment = JSON.parse(
      JSON.stringify({ comment: this.claimForm.get("cicReject").value })
    );

    this.api
      .post("/Claims/" + this.ID + "/reject", comment)
      .subscribe((res) => {
        this.notifier.showSuccess("The claim has been Rejected");
        this.getClaim();
      });
  }
  toBase64(fileDoc) {
    return new Promise((resolve, reject) => {
      if (fileDoc) {
        const reader = new FileReader();
        reader.readAsDataURL(fileDoc);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        resolve(null);
      }
    });
  }
}
