import { Injectable } from '@angular/core';
import { ApiService } from '../components/api.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthorisedService {
  userName:string;
  userRole:string;
  userId:any;
    constructor(private api:ApiService) { }
    CurrentUser(){
      this.userId=localStorage.getItem('userId');
      return this.api.get('/Admins/'+this.userId);

    }
  }
