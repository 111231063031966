
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ApiService } from '../api.service';
import { IsAuthorisedService } from 'src/app/services/is-authorised.service';



@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  ID: string;
  claim: any;
  payForm: FormGroup;
  isLoading = true;

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public notifier: NotificationService,
    private user:IsAuthorisedService
  ) {}
username=this.user.userName;
  get f() {
    return this.payForm.controls;
  }

  ngOnInit() {
    this.ID = this.activatedRoute.snapshot.params.ID;
    this.getClaim();
    this.payForm = this.formBuilder.group({
      payClaim: [''],
      Receipt: [''],
      paymentId:[''],
      amountPaid:[''],
      updatePaymentDay: [''],
    });
  }


  getClaim() {
    this.api.get('/Claims/' + this.ID).subscribe((res) => {
      this.claim = res;
      this.isLoading = false;
    });
  }

  uploadReceipt= async ()=> {

    const file= await this.toBase64(this.payForm.get('Receipt').value);
    const data=JSON.parse( JSON.stringify({receipt: file}));
    this.api.put(`/Claims/${this.ID}/receipt`,data).subscribe(
             res => {
                this.notifier.showSuccess('receipt has been uploaded!');
              }

             );

    console.log (file);
    // .then((receipt:string)=>{


    //   const receiptdoc = { receipt };
    //   const data =JSON.parse(
    //     JSON.stringify(receiptdoc)
    //   );
    //        this.api.put(`/Claims/${this.ID}/receipt`,data).subscribe(
    //        res => {
    //           this.notifier.showSuccess('receipt has been uploaded!');
    //         }
    //        );

    // })



  }
claimFileSelected(event){
if(event.target.files.length>0){
  this.payForm.get('Receipt').setValue(event.target.files[0])
}
console.log(this.claimFileSelected);

}
  updatePaymentDay() {
    const paidAt = JSON.parse(
      JSON.stringify({ paidAt: this.payForm.get('cicApprove').value })
    );

    this.api.post('/Claims',paidAt ).subscribe(
      res => {
        this.notifier.showSuccess('The date has been updated');
        this.getClaim();
      }
    );
  }

  payClaim() {
    const paid = JSON.parse(
      JSON.stringify({})
    );

    this.api.put("/Claims/"+this.claim.id+"/payment", paid).subscribe(
      res => {
        this.notifier.showSuccess('The claim has been Paid');
        this.getClaim();
      }
    );
  }

  paymentId() {
    const paymentId = JSON.parse(
      JSON.stringify({paymentId: this.payForm.get('paymentId').value})
    );

    console.log(paymentId);

    this.api.put("/Claims/"+this.claim.id+"/paymentId", paymentId).subscribe(
      res => {
        this.notifier.showSuccess('Payment Id Updated successfully');
        this.getClaim();
      }
    );
  }
  amountPaid() {
    const amountPaid = JSON.parse(
      JSON.stringify({amountPaid: this.payForm.get('amountPaid').value})
    );
     console.log(amountPaid);

    this.api.put("/Claims/"+this.claim.id+"/amount", amountPaid).subscribe(
      res => {
        this.notifier.showSuccess('Amount paid Updated successfully');
        this.getClaim();
      }
    );
  }

  toBase64(file){
    return  new Promise((resolve, reject) => {

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    } else {
      resolve(null)
    }
  });
  }

}
