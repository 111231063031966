import { PendingClaimsComponent } from './components/pending-claims/pending-claims.component';
import { ReviewedClaimsComponent } from './components/reviewed-claims/reviewed-claims.component';
import { RejectedClaimsComponent } from './components/rejected-claims/rejected-claims.component';
import { ApprovedClaimsComponent } from './components/approved-claims/approved-claims.component';
import { PaidClaimsComponent } from './components/paid-claims/paid-claims.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DetailsComponent } from './components/details/details.component';
import { AdminComponent } from './components/admin/admin.component';
import { AuthGuard } from './auth/auth.guard';
import { PaymentsComponent } from './components/payments/payments.component';



const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'details/:ID', component: DetailsComponent, canActivate: [AuthGuard] },
   { path: 'payments/:ID', component: PaymentsComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent,canActivate: [AuthGuard] ,
children:[{
path:'',
component:DashboardComponent
 },
 {
   path:'paid',
   component:PaidClaimsComponent
 },
 {
  path:'approved',
  component:ApprovedClaimsComponent
},
{
  path:'rejected',
  component:RejectedClaimsComponent
},
{
  path:'payment',
  component:PaymentsComponent
},
{
  path:'reviewed',
  component:ReviewedClaimsComponent
},
{
  path:'details',
  component:DetailsComponent
  },
  {
    path:'pending',
    component:PendingClaimsComponent
  }
]
}

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
